<template>
  <div class="menus">
    <div class="tian">
      <el-button
        type="primary"
        plain
        icon="el-icon-plus"
        size="mini"
        @click="addition()"
        style="float: left"
        >添加</el-button
      >
      <el-upload
        class="upload-demo"
        style="float: left"
        ref="uploads"
        action="/"
        :on-remove="handleRemove"
        :multiple="false"
        :limit="1"
        :before-upload="beforeAvatarUpload"
        :on-exceed="handleExceed"
        :file-list="fileList"
        :show-file-list="false"
      >
        <el-button
          slot="trigger"
          style="margin-left: 10px"
          size="mini"
          type="primary"
          icon="el-icon-upload"
          >上传模板</el-button
        >
      </el-upload>
    </div>

    <el-dialog :title="title" :visible.sync="dialogFormVisible" width="600px">
      <el-form
        ref="froms"
        :model="froms"
        :rules="rules"
        label-width="100px"
        class="demo-ruleForm"
        style="width: 60%; margin: 0 auto"
      >
        <el-form-item label="名称" prop="certificateName">
          <el-input
            v-model="froms.certificateName"
            placeholder="请输入名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="开始时间" prop="startTime">
          <el-date-picker
            style="width: 100%"
            v-model="froms.startTime"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="endTime">
          <el-date-picker
            style="width: 100%"
            v-model="froms.endTime"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item>
                      <el-button type="primary" style="margin-top:15px;" v-if="isClear" @click="submitForm()">保存</el-button>
                      <el-button type="primary" style="margin-top:15px;" v-else @click="modifytForm()">修改</el-button>
                  </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button
          type="primary"
          :disabled="disabled"
          @click="addFytform('froms')"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="预览"
      :visible.sync="VisiblePreview"
      width="60%"
      top="50px"
    >
      <img :src="imgs" alt="" style="width: 100%; height: 800px" />
    </el-dialog>

    <el-table
      :data="tableData"
      style="width: 100%"
      :header-cell-style="{ background: '#F8F8F9', color: '#515A6E' }"
    >
      <el-table-column prop="certificateName" label="名称"> </el-table-column>
      <el-table-column prop="startTime" label="开始时间"> </el-table-column>
      <el-table-column prop="endTime" label="结束时间"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleEdit(scope.$index, scope.row)"
          >
            编辑</el-button
          >
          <el-button
            size="mini"
            type="text"
            icon="el-icon-view"
            @click="handPreviewt(scope.row)"
          >
            预览</el-button
          >
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
          >
            删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="info()"
    />
  </div>
</template>
  
  
  
  <script>
import request from "@/utils/request.js";
import axios from "axios";
import { getToken } from "@/utils/auth";

export default {
  data() {
    return {
      pagesize: 10, //分页默认一页显示15条数据
      currpage: 1, //分页默认显示第1页数据
      pageSizes: [10, 30, 50], //分页可选择一页多少条数据
      pageNo: 1, //强行第一页
      total: 0, //共多少条数据从接口获取
      pageCount: 0, //共多少页数据从接口获取
      dialogFormVisible: false,
      VisiblePreview: false,
      title: "",
      msg: "",
      value: "",
      htmls: "",
      froms: {
        certificateName: undefined,
        startTime: undefined,
        endTime: undefined,
      },
      isClear: true,
      tableData: [],
      stateu: 1,
      queryParams: {
        pageSize: 10, //分页默认一页显示15条数据
        pageNum: 1, //分页默认显示第1页数据
      },
      imgs: "",
      disabled: false,
      rules: {
        certificateName: [
          { required: true, message: "请输入名称", trigger: "blur" },
        ],
        startTime: [
          {
            type: "string",
            required: true,
            message: "请选择开始日期",
            trigger: "change",
          },
        ],
        endTime: [
          {
            type: "string",
            required: true,
            message: "请选择结束日期",
            trigger: "change",
          },
        ],
      },
      file: [],
      fileList: [],
    };
  },
  created() {
    this.info();
  },

  methods: {
    //添加示例图片
    handleRemove(file) {
      this.file = [];
    },
    async beforeAvatarUpload(file) {
      let chunkSize = 20 * 1024 * 1024; //20M
      let size = file.size;
      this.fileName = file.name;
      if (size <= chunkSize) {
        let formData = new FormData();
        formData.append("file", file);
        // http://192.168.3.110:8087/config/uploadFile
        // https://hbpxedu.boeyu.com/exam/config/uploadFile
        await axios
          .post("https://hbpxedu.boeyu.com/exam/config/uploadFile", formData, {
            headers: {
              Authorization: "Bearer " + getToken(),
              "Content-Type": "multipart/form-data;charset=utf-8",
            },
          })
          .then((res) => {
            if (res.data.status == 200) {
              this.$message.success("上传成功");
            } else {
              this.$message.error("上传失败，请重试！");
            }
          });
      }
    },
    handleExceed() {
      this.$message.error("请先移除当前文件再上传");
    },
    //  富文本事件
    change(val) {
      this.value = val;
    },
    info() {
      return request({
        url: "certificateDate/selectCertificateDate",
        method: "get",
        params: this.queryParams,
      }).then((res) => {
        if (res.status === 200) {
          this.tableData = res.data.certificateDates;
          this.queryParams.pageNum = res.data.pageBean.pageNum; //接口获取到的共多少页
          this.total = res.data.pageBean.count; //接口获取到的共多少条
          //  if(res.data !== undefined){
          //     this.froms = res.data;
          //     this.isClear = false
          //  }
        }
      });
    },
    cancel() {
      this.dialogFormVisible = false;
      this.froms = {
        certificateName: undefined,
        startTime: undefined,
        endTime: undefined,
      };
      this.$refs["froms"].resetFields(); // 清空表单
    },

    addFytform(ruleForm) {
      this.disabled = false;
      if (this.stateu === 1) {
        this.submitForm(ruleForm);
      }
      if (this.stateu === 2) {
        this.modifytForm(ruleForm);
      }
    },
    submitForm(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.disabled = true;
          let datas = {
            startTime: this.froms.startTime,
            endTime: this.froms.endTime,
            certificateName: this.froms.certificateName,
          };
          return request({
            url: "certificateDate/insertCertificateDate",
            method: "post",
            data: datas,
          }).then(
            (res) => {
              if (res.status === 200) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                });
                this.disabled = false;
                this.dialogFormVisible = false;
                this.info();
              }
            },
            (err) => {
              this.disabled = false;
            }
          );
        } else {
          return false;
        }
      });
    },
    //    修改
    modifytForm(ruleForm) {
      this.disabled = true;
      let datas = {
        id: this.froms.certificateDateId,
        startTime: this.froms.startTime,
        endTime: this.froms.endTime,
        certificateName: this.froms.certificateName,
      };
      return request({
        url: "certificateDate/updateCertificateDate",
        method: "post",
        data: datas,
      }).then(
        (res) => {
          if (res.status === 200) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.disabled = false;
            this.dialogFormVisible = false;
            this.froms = {
              certificateName: undefined,
              startTime: undefined,
              endTime: undefined,
            };
            this.resetForm("froms");
            this.info();
          }
        },
        (err) => {
          this.disabled = false;
        }
      );
    },
    addition() {
      this.froms = {
        certificateName: undefined,
        startTime: undefined,
        endTime: undefined,
      };
      this.dialogFormVisible = true;
      this.stateu = 1;
      this.title = "添加";
    },
    //编辑弹窗
    handleEdit(index, row) {
      this.dialogFormVisible = true;
      this.stateu = 2;
      this.title = "修改";
      this.froms = Object.assign({}, row);
    },
    //删除
    handleDelete(row) {
      let obj = {
        id: row.certificateDateId,
      };
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return request({
            url: "certificateDate/deleteCertificateDate",
            method: "post",
            data: obj,
          }).then((res) => {
            if (res.status === 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.info();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handPreviewt(row) {
      this.imgs = row.preview;
      this.VisiblePreview = true;
    },
  },
};
</script>
  
  <style scoped>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
.tian {
  width: 100%;
  height: 60px;
}
.el-dialog {
  width: 500px !important;
}
.el-form {
  width: auto;
  height: auto;
}
.el-pagination {
  text-align: right;
}
</style>
   
  